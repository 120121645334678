import { Injectable, ErrorHandler } from "@angular/core";

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(private snackBar: MatSnackBar) { }

    handleError(error: any) {
        this.showSnackBar(error);
        throw error;
    }

    private showSnackBar(msg: any) {
        this.snackBar.open(msg.toString(), null, { duration: 3000 });
    }
}