import { Purchase } from '@app/data-access/entities/supply_chain/purchase';

export const MOCK_PURCHASES: Purchase[] = [
    {
        id: 1, rawmaterial_id: 1, rawmaterialgroup_id: 1, supplier_id: 1, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1591695925000 
    },
    {
        id: 2, rawmaterial_id: 2, rawmaterialgroup_id: 1, supplier_id: 2, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1591015925000
    },
    {
        id: 3, rawmaterial_id: 3, rawmaterialgroup_id: 1, supplier_id: 3, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1581495925000
    },
    {
        id: 4, rawmaterial_id: 1, rawmaterialgroup_id: 1, supplier_id: 4, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1590095925000
    },
    {
        id: 5, rawmaterial_id: 2, rawmaterialgroup_id: 1, supplier_id: 5, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1591295925000
    },
    {
        id: 6, rawmaterial_id: 3, rawmaterialgroup_id: 1, supplier_id: 5, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1591035925000
    },
    {
        id: 7, rawmaterial_id: 4, rawmaterialgroup_id: 2, supplier_id: 5, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1590695925000
    },
    {
        id: 8, rawmaterial_id: 5, rawmaterialgroup_id: 3, supplier_id: 5, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1589695925000
    },
    {
        id: 9, rawmaterial_id: 4, rawmaterialgroup_id: 2, supplier_id: 5, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD',
        active: true, createdby_id: 1, timestamp: 1590195925000
    },
    {
        id: 101, rawmaterial_id: 1, rawmaterialgroup_id: 1, supplier_id: 1, gross_weight: 100, net_weight: 77, pallet_id: 1, case_lots: [],
        vehicle_plate: 'AB1234CD', active: false, createdby_id: 1, timestamp: 0
    }
];
