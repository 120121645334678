import { ShipmentLot } from '@app/data-access/entities/crm/shipment-lot';

export const MOCK_SHIPMENTLOTS: ShipmentLot[] = [
    {
        id: 1, 
        customer_id: 1,
        customer_lot_id: 'LCUSTOM',
        invoice_url: null,
        transportation_id: 1,
        items: [
            { productionlot_id: 1, quantity: 100 }
        ],
        active: true,
        createdby_id: 1,
        timestamp: 0
    },
    {
        id: 2, 
        customer_id: 1,
        customer_lot_id: null,
        invoice_url: null,
        transportation_id: 1,
        items: [
            { productionlot_id: 2, quantity: 100 }
        ],
        active: true,
        createdby_id: 1,
        timestamp: 0
    },
    {
        id: 3, 
        customer_id: 2,
        customer_lot_id: 'LCUSTOM-3',
        invoice_url: 'https://google.com',
        transportation_id: 1,
        items: [
            { productionlot_id: 3, quantity: 100 }
        ],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    }
];
