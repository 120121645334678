import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthService } from './i-auth.service';

// https://stackblitz.com/angular/barmengxkej?file=src%2Fapp%2Fauth-guard.service.ts
// need to use MAP!
// https://stackoverflow.com/questions/38120673/how-to-implement-authguard-waiting-a-connection-in-angular-2

@Injectable()
export class AuthGuardService  {

  constructor(
      @Inject('AuthService') private authService: IAuthService, 
      private router: Router) {}

  async canActivate(): Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      return this.authService.isAuthenticated()
    } else {
      this.router.navigate(['login'])
    }
  }

  async canActivateChild(): Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      return this.authService.isAuthenticated()
    } else {
      this.router.navigate(['login'])
    }
  }

  async canLoad(): Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      return this.authService.isAuthenticated()
    } else {
      this.router.navigate(['login'])
    }
  }
}
