import { Department } from '@app/data-access/entities/hr/department';

export const MOCK_DEPARTMENTS: Department[] = [
    { id: 1, name: 'Замразяване', handle: 'freezing', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Сортиране', handle: 'sorting', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Изкупуване', handle: 'purchasing', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Съхранение', handle: 'storage', active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'Качествен контрол', handle: 'quality_control', active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, name: 'Измиване', handle: 'washing', active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, name: 'Пакетиране', handle: 'packing', active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, name: 'inactive department', handle: 'inactive', active: false, createdby_id: 1, timestamp: 0 }
];