import { EmployeeRole } from '@app/data-access/entities/hr/employee-role';

export const MOCK_EMPLOYEEROLES: EmployeeRole[] = [
    { id: 1, employee_id: 1, role_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, employee_id: 1, role_id: 9, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, employee_id: 2, role_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, employee_id: 2, role_id: 2, active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, employee_id: 2, role_id: 8, active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, employee_id: 2, role_id: 24, active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, employee_id: 3, role_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 17, employee_id: 3, role_id: 16, active: true, createdby_id: 1, timestamp: 0 },
    { id: 27, employee_id: 3, role_id: 17, active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, employee_id: 4, role_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 9, employee_id: 5, role_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 10, employee_id: 6, role_id: 7, active: true, createdby_id: 1, timestamp: 0 },
    { id: 11, employee_id: 1, role_id: 25, active: true, createdby_id: 0, timestamp: 0 },
    { id: 11, employee_id: 5741587778764800, role_id: 25, active: true, createdby_id: 0, timestamp: 0 },
    { id: 101, employee_id: 1, role_id: 1, active: false, createdby_id: 1, timestamp: 0 }
];
