import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `<h1 style="font-family: 'BlenderPro-Heavy', helvetica;font-size:3em;color: #06386b;">404. Page not found</h1>`
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
