import { ProductPackaging } from '@app/data-access/entities/production/product-packaging';

export const MOCK_PRODUCTPACKAGING: ProductPackaging[] = [
    { id: 1, product_id: 1, entity_kind: 'Bag', entity_id: 1, quantity: 1, quantity_unit: 'бр', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, product_id: 1, entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: 'бр', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, product_id: 1, entity_kind: 'Tape', entity_id: 1, quantity: 1.2, quantity_unit: 'm', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, product_id: 2, entity_kind: 'Bag', entity_id: 2, quantity: 1, quantity_unit: 'бр', active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, product_id: 2, entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: 'бр', active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, product_id: 2, entity_kind: 'Tape', entity_id: 1, quantity: 1.2, quantity_unit: 'm', active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, product_id: 3, entity_kind: 'Bag', entity_id: 1, quantity: 1, quantity_unit: 'бр', active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, product_id: 3, entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: 'бр', active: true, createdby_id: 1, timestamp: 0 },
    { id: 9, product_id: 3, entity_kind: 'Tape', entity_id: 1, quantity: 1.2, quantity_unit: 'm', active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, product_id: 1, entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: 'бр', active: false, createdby_id: 1, timestamp: 0, timestamp_end: 1 },
];
