import { DepartmentRole } from '@app/data-access/entities/hr/department-role';

export const MOCK_DEPARTMENTROLES: DepartmentRole[] = [
    { id: 1, department_id: 1, role_id: 3, active: true, createdby_id: 1, timestamp: 0},
    { id: 2, department_id: 1, role_id: 19, active: true, createdby_id: 1, timestamp: 0},
    { id: 3, department_id: 2, role_id: 7, active: true, createdby_id: 1, timestamp: 0},
    { id: 4, department_id: 3, role_id: 16, active: true, createdby_id: 1, timestamp: 0},
    { id: 5, department_id: 3, role_id: 17, active: true, createdby_id: 1, timestamp: 0},
    { id: 6, department_id: 4, role_id: 11, active: true, createdby_id: 1, timestamp: 0},
    { id: 7, department_id: 6, role_id: 4, active: true, createdby_id: 1, timestamp: 0},
    { id: 8, department_id: 2, role_id: 6, active: true, createdby_id: 1, timestamp: 0},
    { id: 101, department_id: 4, role_id: 4, active: false, createdby_id: 1, timestamp: 0, timestamp_end: 1},
];