import { SupplierRawMaterial } from '@app/data-access/entities/supply_chain/suplier-rawmaterial';

export const MOCK_SUPPLIERRAWMATERIALS: SupplierRawMaterial[] = [
    { id: 1, supplier_id: 1, rawmaterial_id: 1, rawmaterialgroup_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, supplier_id: 1, rawmaterial_id: 2, rawmaterialgroup_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, supplier_id: 1, rawmaterial_id: 3, rawmaterialgroup_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, supplier_id: 1, rawmaterial_id: 4, rawmaterialgroup_id: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, supplier_id: 2, rawmaterial_id: 1, rawmaterialgroup_id: 2, active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, supplier_id: 2, rawmaterial_id: 4, rawmaterialgroup_id: 2, active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, supplier_id: 3, rawmaterial_id: 5, rawmaterialgroup_id: 3, active: true, createdby_id: 1, timestamp: 0 },
    { id: 9, supplier_id: 6, rawmaterial_id: 5, rawmaterialgroup_id: 3, active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, supplier_id: 1, rawmaterial_id: 1, rawmaterialgroup_id: 1, active: false, createdby_id: 1, timestamp: 0 }
];
