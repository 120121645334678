
import { CleaningArea } from '@app/data-access/entities/cleaning/cleaning-area';

export const MOCK_CLEANINGAREAS: CleaningArea[] = [
    {
        id: 1, name: 'Приемна зала', contact_surface: false, waste_disposal: false, checkpoints: [
            'Под', 'Стени', 'Кантар'
        ], department_ids: [
            3
        ], cleaningagent_ids: [
            1
        ], active: true, createdby_id: 1, timestamp: 0
    },
    {
        id: 2, name: 'Зала за сортиране', contact_surface: false, waste_disposal: false, checkpoints: [
            'Под', 'Стени'
        ], department_ids: [
            1
        ], cleaningagent_ids: [
            1
        ], active: true, createdby_id: 1, timestamp: 0
    }
];
