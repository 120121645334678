import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectivePreloadingStrategy } from '@app/core/selective-preloading-strategy';
import { PageNotFoundComponent } from '@app/not-found.component';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { CallbackComponent } from './homepage/auth/callback/callback.component';
import { LoginComponent } from './homepage/auth/login/login.component';
import { UnauthorizedComponent } from './homepage/auth/unauthorized/unauthorized.component';

// https://stackblitz.com/angular/barmengxkej
// sync load: loadChildren: () => SuppliesModule
// async load: loadChildren: 'app/gmp/gmp.module#GmpModule'

const appRoutes: Routes = [
    {
        path: 'intranet',
        loadChildren: () => import('./intranet/intranet.module')
            .then(x => x.IntranetModule),
        canLoad: [AuthGuardService]
    },
    {
        path: '',
        loadChildren: () => import('./homepage/homepage.module')
            .then(x => x.HomepageModule)
    },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                scrollPositionRestoration: 'enabled',
                enableTracing: false,
                preloadingStrategy: SelectivePreloadingStrategy,
                //relativeLinkResolution: 'legacy'
            }
        )
    ],
    exports: [
        RouterModule
    ],
    declarations: [],
    providers: [
        SelectivePreloadingStrategy
    ]
})
export class AppRoutingModule { }
