import { SupplierType } from '@app/data-access/entities/supply_chain/supplier-type';

export const MOCK_SUPPLIERTYPES: SupplierType[] = [
    { id: 1, name: 'Хигиенни материали', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Оборудване', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Измервателни инструменти', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Производствено оборудване', active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'Опаковъчни материали', active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, name: 'Суровини', active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, name: 'Спомагателни материали', active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, name: 'Транспортни опаковки', active: true, createdby_id: 1, timestamp: 0 },
    { id: 9, name: 'inactive', active: false, createdby_id: 1, timestamp: 0 }
];
