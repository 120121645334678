
import { ContactPerson } from '@app/data-access/entities/hr/contact-person';

export const MOCK_CONTACTPERSONS: ContactPerson[] = [
    { id: 1, name: 'contact person 1', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'contact person 2', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'contact person 3', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'contact person 4', active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, name: 'inactive', active: false, createdby_id: 1, timestamp: 0 },
];
