import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IUser } from './i-user';
import { IAuthorizeUrl } from './i-authorize-url';
import { IAuthService } from './i-auth.service';

@Injectable()
export class AuthService implements IAuthService {
    storage = sessionStorage
    storageKey = 'user'
    authStateChanged$ = new BehaviorSubject<IUser | undefined>(this.user)

    constructor(
        private _http: HttpClient) { }

    public get user(): IUser | undefined {
        if (this.storage.getItem(this.storageKey)) {
            const user = <IUser>JSON.parse(this.storage.getItem(this.storageKey))

            if (user) {
                if (user.expiry_date > Date.now()) {
                    return user
                } else {
                    //this._logger.debug('destroying expired user session...')
                    this.setUser(undefined)
                }
            }
        }

        return undefined
    }

    authorize(): Observable<IAuthorizeUrl> {
        return this._http.get<IAuthorizeUrl>('/auth/authorize')
    }

    authenticate(code: string): Observable<IUser | undefined> {
        return this._http.get<IUser | undefined>(`auth/authenticate?code=${code}`)
    }

    isAuthenticated(): boolean {
        return this.user != undefined
    }

    setUser(user: IUser | undefined) {
        if (user) {
            this.storage.setItem(this.storageKey, JSON.stringify(user))
        } else {
            this.storage.removeItem(this.storageKey)
        }

        this.authStateChanged$.next(user)
    }
}
