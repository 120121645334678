import { Injectable } from '@angular/core';
import { InMemoryDbService, ResponseOptions, STATUS, getStatusText } from 'angular-in-memory-web-api';
import { RequestInfo } from 'angular-in-memory-web-api';
import { MOCK_AIRFILTERS } from './maintenance/air-filters/mock-data';
import { MOCK_ASSETMAINTENANCELOGS } from './maintenance/asset-maintenance-logs/mock-data';
import { MOCK_ASSETFAILURELOGS } from './maintenance/asset-failure-logs/mock-data';
import { MOCK_ASSETS } from './assets/assets/mock-data';
import { MOCK_TASKCONFIGS } from './automation/task-configs/mock-data';
import { MOCK_CLEANINGAREACHECKPOINTLOGS } from './cleaning/cleaning-area-checkpoint-logs/mock-data';
import { MOCK_CLEANINGAGENTS } from './cleaning/cleaning-agents/mock-data';
import { MOCK_CLEANINGAREAS } from './cleaning/cleaning-areas/mock-data';
import { MOCK_CLEANINGAGENTLOTS } from './cleaning/cleaning-agent-lots/mock-data';
import { MOCK_CONTACTSURFACESCONTROLLOGS } from './cleaning/contact-surfaces-control-logs/mock-data';
import { MOCK_DISINFECTIONLOGS } from './cleaning/disinfection-logs/mock-data';
import { MOCK_PERSONNELHYGIENELOGS } from './cleaning/personnel-hygiene-logs/mock-data';
import { MOCK_REPEATEDDISINFECTIONLOGS } from './cleaning/repeated-disinfection-logs/mock-data';
import { MOCK_SUPPLIERCLEANINGAGENTS } from './cleaning/supplier-cleaning-agents/mock-data';
import { MOCK_WASTEDISPOSALLOGS } from './cleaning/waste-disposal-logs/mock-data';
import { MOCK_WORKWEAR } from './cleaning/workwear/mock-data';
import { MOCK_BAGS } from './packaging/bags/mock-data';
import { MOCK_BAGINVENTORIES } from './packaging/bag-inventories/mock-data';
import { MOCK_BOXES } from './packaging/boxes/mock-data';
import { MOCK_BOXINVENTORIES } from './packaging/box-inventories/mock-data';
import { MOCK_CASES } from './packaging/cases/mock-data';
import { MOCK_COOLINGLOGS } from './production/cooling-logs/mock-data';
import { MOCK_CONFORMITYDECLARATIONS } from './supply_chain/conformity-declarations/mock-data';
import { MOCK_CORRECTIVEACTIONS } from './haccp/corrective-actions/mock-data';
import { MOCK_CRITICALCONTROLPOINTS } from './haccp/critical-control-points/mock-data';
import { MOCK_CRITICALCONTROLPOINTWAREHOUSES } from './haccp/critical-control-point-warehouses/mock-data';
import { MOCK_DEPARTMENTS } from './hr/departments/mock-data';
import { MOCK_DISCARDEDPRODUCTIONLOTS } from './production/discarded-production-lots/mock-data';
import { MOCK_EMPLOYEES } from './hr/employees/mock-data';
import { MOCK_FOREIGNMATERIALDISCOVERYLOGS } from './production/foreign-material-discovery-logs/mock-data';
import { MOCK_HIRINGS } from './hr/hirings/mock-data';
import { MOCK_INCIDENTS } from './haccp/incidents/mock-data';
import { MOCK_INSTRUMENTCALIBRATIONLOGS } from './measurement/instrument-calibration-logs/mock-data';
import { MOCK_INSTRUMENTS } from './measurement/instruments/mock-data';
import { MOCK_INSTRYMENTTYPES } from './measurement/instrument-types/mock-data';
import { MOCK_LABELTAPES } from './packaging/label-tapes/mock-data';
import { MOCK_LABORATORIES } from './qualitycontrol/laboratories/mock-data';
import { MOCK_LABORATORYCONTROLLOGS } from './qualitycontrol/laboratory-control-logs/mock-data';
import { MOCK_PACKAGINGLOGS } from './packaging/packaging-logs/mock-data';
import { MOCK_PALLETS } from './packaging/pallets/mock-data';
import { MOCK_PRODUCTCONTROLLOGS } from './qualitycontrol/product-control-logs/mock-data';
import { MOCK_PRODUCTIONLOTS } from './production/production-lots/mock-data';
import { MOCK_PRODUCTQUALITIES } from './production/product-qualities/mock-data';
import { MOCK_PRODUCTS } from './production/products/mock-data';
import { MOCK_PURCHASES } from './supply_chain/purchases/mock-data';
import { MOCK_PURCHASECASELOTS } from './supply_chain/purchase-case-lots/mock-data';
import { MOCK_RAWMATERIALS } from './materials/rawmaterials/mock-data';
import { MOCK_RAWMATERIALGROUPS } from './materials/rawmaterialgroups/mock-data';
import { MOCK_RAWMATERIALLOTS } from './materials/rawmaterial-lots/mock-data';
import { MOCK_ROLES } from './hr/roles/mock-data';
import { MOCK_SHIPMENTLOTS } from './crm/shipment-lots/mock-data';
import { MOCK_SUPPLIERS } from './supply_chain/suppliers/mock-data';
import { MOCK_SUPPLIERSCORECARDS } from './supply_chain/supplier-scorecards/mock-data';
import { MOCK_SUPPLIERTYPES } from './supply_chain/supplier-types/mock-data';
import { MOCK_SUPPLIERRAWMATERIALS } from './supply_chain/supplier-rawmaterials/mock-data';
import { MOCK_TAPES } from './packaging/tapes/mock-data';
import { MOCK_TAPEINVENTORY } from './packaging/tape-inventories/mock-data';
import { MOCK_TECHNOLOGICALPROCESSES } from './production/technological-processes/mock-data';
import { MOCK_TECHNOLOGICALSTAGES } from './production/technological-stages/mock-data';
import { MOCK_TEMERATURERECORDS } from './haccp/temperature-records/mock-data';
import { MOCK_TRAPLOCATIONS } from './pest-control/trap-locations/mock-data';
import { MOCK_TRAPMONITORINGLOGS } from './pest-control/trap-monitoring-logs/mock-data';
import { MOCK_WAREHOUSES } from './warehouse/warehouses/mock-data';
import { MOCK_WATERCONTROLLOGS } from './qualitycontrol/water-control-logs/mock-data';
import { MOCK_WATERSOURCES } from './warehouse/water-sources/mock-data';
import { MOCK_WRAPS } from './packaging/wraps/mock-data';
import { MOCK_WORKSHIFTS } from './hr/workshifts/mock-data';
import { MOCK_EMPLOYEEROLES } from './hr/employee-roles/mock-data';
import { MOCK_HEALTHRECORDS } from './hr/health-records/mock-data';
import { MOCK_DEPARTMENTROLES } from './hr/department-roles/mock-data';
import { MOCK_WORKSHIFTEMPLOYEES } from './hr/workshift-employees/mock-data';
import { HttpRequest } from '@angular/common/http';
import { MOCK_CONTACTPERSONS } from './hr/contact-persons/mock-data';
import { MOCK_ADDRESSES } from './hr/addresses/mock-data';
import { MOCK_DOCUMENTS } from './documentation/documents/mock-data';
import { MOCK_DOCUMENTLABELS } from './documentation/document-labels/mock-data';
import { MOCK_CUSTOMERS } from './crm/customers/mock-data';
import { MOCK_TRANSPORTATION } from './transportation/transportation/mock-data';
import { MOCK_TRANSPORTCOMPANIES } from './transportation/transport-companies/mock-data';
import { MOCK_PRODUCTINGREDIENTS } from './production/product-ingredients/mock-data';
import { MOCK_PRODUCTPACKAGING } from './production/product-packaging/mock-data';
import { MOCK_COMPLAINTLOGS } from './crm/complaint-logs/mock-data';
import { MOCK_VISITORS } from './web/visitors/mock-data';
import { MOCK_AIRFILTERUSAGELOGS } from './maintenance/air-filter-usage-logs/mock-data';
import { MOCK_BOOKMARKS } from './web/bookmarks/mock-data';

@Injectable({
    providedIn: 'root'
})
export class InMemoryDb implements InMemoryDbService {
    createDb() {
        return {
            addresses: MOCK_ADDRESSES,
            airfilters: MOCK_AIRFILTERS,
            airfilterusagelogs: MOCK_AIRFILTERUSAGELOGS,
            assetfailurelogs: MOCK_ASSETFAILURELOGS,
            assetmaintenancelogs: MOCK_ASSETMAINTENANCELOGS,
            assets: MOCK_ASSETS,
            baginventories: MOCK_BAGINVENTORIES,
            bags: MOCK_BAGS,
            bookmarks: MOCK_BOOKMARKS,
            boxes: MOCK_BOXES,
            boxinventories: MOCK_BOXINVENTORIES,
            cases: MOCK_CASES,
            cleaningagentlots: MOCK_CLEANINGAGENTLOTS,
            cleaningagents: MOCK_CLEANINGAGENTS,
            cleaningareacheckpointlogs: MOCK_CLEANINGAREACHECKPOINTLOGS,
            cleaningareas: MOCK_CLEANINGAREAS,
            coolinglogs: MOCK_COOLINGLOGS,
            complaintlogs: MOCK_COMPLAINTLOGS,
            conformitydeclarations: MOCK_CONFORMITYDECLARATIONS,
            contactpersons: MOCK_CONTACTPERSONS,
            contactsurfacescontrollogs: MOCK_CONTACTSURFACESCONTROLLOGS,
            correctiveactions: MOCK_CORRECTIVEACTIONS,
            criticalcontrolpoints: MOCK_CRITICALCONTROLPOINTS,
            criticalcontrolpointwarehouses: MOCK_CRITICALCONTROLPOINTWAREHOUSES,
            customers: MOCK_CUSTOMERS,
            departmentroles: MOCK_DEPARTMENTROLES,
            departments: MOCK_DEPARTMENTS,
            discardedproductionlots: MOCK_DISCARDEDPRODUCTIONLOTS,
            disinfectionlogs: MOCK_DISINFECTIONLOGS,
            documentlabels: MOCK_DOCUMENTLABELS,
            documents: MOCK_DOCUMENTS,
            employees: MOCK_EMPLOYEES,
            employeeroles: MOCK_EMPLOYEEROLES,
            foreignmaterialdiscoverylogs: MOCK_FOREIGNMATERIALDISCOVERYLOGS,
            healthrecords: MOCK_HEALTHRECORDS,
            hiring: MOCK_HIRINGS,
            incidents: MOCK_INCIDENTS, // HACCP
            instrumentcalibrationlogs: MOCK_INSTRUMENTCALIBRATIONLOGS,
            instruments: MOCK_INSTRUMENTS,
            instrumenttypes: MOCK_INSTRYMENTTYPES,
            labeltapes: MOCK_LABELTAPES,
            laboratories: MOCK_LABORATORIES,
            laboratorycontrollogs: MOCK_LABORATORYCONTROLLOGS,
            packaginglogs: MOCK_PACKAGINGLOGS,
            pallets: MOCK_PALLETS,
            productcontrollogs: MOCK_PRODUCTCONTROLLOGS,
            productingredients: MOCK_PRODUCTINGREDIENTS,
            productionlots: MOCK_PRODUCTIONLOTS,
            productpackaging: MOCK_PRODUCTPACKAGING,
            productqualities: MOCK_PRODUCTQUALITIES,
            products: MOCK_PRODUCTS,
            personnelhygienelogs: MOCK_PERSONNELHYGIENELOGS,
            purchases: MOCK_PURCHASES,
            purchasecaselots: MOCK_PURCHASECASELOTS, // TODO: remove
            rawmaterials: MOCK_RAWMATERIALS,
            rawmaterialgroups: MOCK_RAWMATERIALGROUPS,
            rawmateriallots: MOCK_RAWMATERIALLOTS,
            repeateddisinfectionlogs: MOCK_REPEATEDDISINFECTIONLOGS,
            roles: MOCK_ROLES,
            shipmentlots: MOCK_SHIPMENTLOTS,
            suppliercleaningagents: MOCK_SUPPLIERCLEANINGAGENTS,
            suppliers: MOCK_SUPPLIERS,
            supplierscorecards: MOCK_SUPPLIERSCORECARDS,
            supplierrawmaterials: MOCK_SUPPLIERRAWMATERIALS,
            suppliertypes: MOCK_SUPPLIERTYPES,
            tapes: MOCK_TAPES,
            tapeinventories: MOCK_TAPEINVENTORY,
            taskconfigs: MOCK_TASKCONFIGS,
            technologicalprocesses: MOCK_TECHNOLOGICALPROCESSES,
            technologicalstages: MOCK_TECHNOLOGICALSTAGES,
            temperaturerecords: MOCK_TEMERATURERECORDS,
            transportcompanies: MOCK_TRANSPORTCOMPANIES,
            transportation: MOCK_TRANSPORTATION,
            traplocations: MOCK_TRAPLOCATIONS,
            trapmonitoringlogs: MOCK_TRAPMONITORINGLOGS,
            visitors: MOCK_VISITORS,
            warehouses: MOCK_WAREHOUSES,
            wastedisposallogs: MOCK_WASTEDISPOSALLOGS,
            watercontrollogs: MOCK_WATERCONTROLLOGS,
            watersources: MOCK_WATERSOURCES,
            workshiftemployees: MOCK_WORKSHIFTEMPLOYEES,
            workshifts: MOCK_WORKSHIFTS,
            workwear: MOCK_WORKWEAR,
            workwearlogs: MOCK_WASTEDISPOSALLOGS,
            wraps: MOCK_WRAPS,
        };
    }

    options(reqInfo: RequestInfo) {
        console.log('options');
        console.log(reqInfo);
    }

    put(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const dataEncapsulation = reqInfo.utils.getConfig().dataEncapsulation;
            const data = (<HttpRequest<any>>reqInfo.req).body;

            const options: ResponseOptions = data ?
                {
                    body: dataEncapsulation ? { data } : data,
                    status: STATUS.OK
                } :
                {
                    body: { error: `NULL not found` },
                    status: STATUS.NOT_FOUND
                };
            return this.finishOptions(options, reqInfo);
        });
    }

    private finishOptions(options: ResponseOptions, { headers, url }: RequestInfo) {
        options.statusText = getStatusText(options.status);
        options.headers = headers;
        options.url = url;
        return options;
    }
}
