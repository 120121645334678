import { RawMaterialLot } from '@app/data-access/entities/materials/rawmaterial-lot';

export const MOCK_RAWMATERIALLOTS: RawMaterialLot[] = [
    { id: 1, rawmaterial_id: 1, rawmaterialgroup_id: 1, purchase_id: 1, quantity: 111, active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, rawmaterial_id: 2, rawmaterialgroup_id: 1, purchase_id: 2, quantity: 222, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, rawmaterial_id: 3, rawmaterialgroup_id: 1, purchase_id: 3, quantity: 333, active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, rawmaterial_id: 1, rawmaterialgroup_id: 1, purchase_id: 4, quantity: 444, active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, rawmaterial_id: 2, rawmaterialgroup_id: 1, purchase_id: 5, quantity: 555, active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, rawmaterial_id: 3, rawmaterialgroup_id: 1, purchase_id: 6, quantity: 66, active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, rawmaterial_id: 4, rawmaterialgroup_id: 2, purchase_id: 7, quantity: 77, active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, rawmaterial_id: 5, rawmaterialgroup_id: 3, purchase_id: 8, quantity: 88, active: true, createdby_id: 1, timestamp: 0 },
    { id: 9, rawmaterial_id: 4, rawmaterialgroup_id: 2, purchase_id: 9, quantity: 99, active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, rawmaterial_id: 1, rawmaterialgroup_id: 1, purchase_id: 10, quantity: 100, active: false, createdby_id: 1, timestamp: 0 }
];