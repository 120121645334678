import { Document } from '@app/data-access/entities/documentation/document';

export const MOCK_DOCUMENTS: Document[] = [
    { id: 1, handle: 'a', title: 'ДПП 01', subtitle: 'Сгради, помещения и оборудване', url: '/intranet/gmp/facilities', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, handle: 'b', title: 'ДПП 02', subtitle: 'Прием на суровини, опаковки и пакетиращи материали', url: '/intranet/gmp/supplies', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, handle: 'c', title: 'ДПП 03', subtitle: 'Съхранение на суровини, опаковки и пакетиращи материали', url: '/intranet/gmp/storage', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, handle: 'd', title: 'HACCP Doc 1', url: 'https://www.google.com', labels: ['HACCP'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, handle: 'e', title: 'HACCP Doc 2', url: 'https://www.google.com', labels: ['HACCP'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, handle: 'f', title: 'HACCP Doc 3', url: 'https://www.google.com', labels: ['HACCP'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, handle: 'g', title: 'HACCP Doc 4', url: 'https://www.google.com', labels: ['HACCP'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 04', subtitle: 'Поддържане на хигиенното състояние на обекта', url: '/intranet/gmp/supplies/packaging-logs', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 05', subtitle: 'Технологичен процес', url: '/intranet/gmp/technological-process', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 06', subtitle: 'Лична хигиена на персонала', url: '/intranet/gmp/personnel-hygiene', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 07', subtitle: 'Обучение на персонала', url: '/intranet/gmp/training', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 08', subtitle: 'Борба с вредители', url: '/intranet/gmp/supplies/pest-control', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 09', subtitle: 'Програма за самонаблюдение', url: '/intranet/gmp/self-control', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 10', subtitle: 'Изтегляне на продукти от пазара', url: '/intranet/gmp/product-withdrawal', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 11', subtitle: 'Съхранение и реализация на готови продукти', url: '/intranet/gmp/product-storage', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 12', subtitle: 'Предпазване на храните от чужди тела и вещества', url: '/intranet/gmp/foreign-body-management', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 13', subtitle: 'Програма за отстраняване на отпадъци', url: '/intranet/gmp/waste-management', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, handle: 'h', title: 'ДПП 14', subtitle: 'Химични вещества за почистване и дезинфекция', url: '/intranet/gmp/harmful-chemicals-management', labels: ['ДПП'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 10, handle: 'j', title: 'ТД 1', subtitle: 'Дълбоко замразена биологична малина', url: 'https://www.google.com', labels: ['ТД'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, handle: 'z', title: 'z', url: 'https://www.google.com', labels: [], active: false, createdby_id: 1, timestamp: 0 },
];
