import { HttpEvent, HttpRequest, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IAuthService } from '../auth/i-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        @Inject('AuthService') private authService: IAuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authService.user?.access_token}`
            }
        })
        
        return next.handle(request)
    }
}
