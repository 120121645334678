import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth-interceptor';
import { BlockUIInterceptor } from './blockui-interceptor';
import { CachingInterceptor } from './caching-interceptor';
import { ProgressBarInterceptor } from './progress-bar-interceptor';

/** Http interceptor providers in outside-in (reverse) order
 * https://stackblitz.com/angular/jyrxkavlvap?file=src%2Fapp%2Fhttp-interceptors%2Findex.ts
*/
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: BlockUIInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true }
];
