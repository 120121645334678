import { ENVIRONMENTS } from "./envs";

export const environment = {
  name: ENVIRONMENTS.Development,
  production: false,
  logLevel: 5,
  firebase: {
    apiKey: "AIzaSyAcXKno9msUMNlQWC1xTMdJpWqjeaoMfu8",
    authDomain: "rpk-staraplanina.firebaseapp.com",
    databaseURL: "https://rpk-staraplanina.firebaseio.com",
    projectId: "rpk-staraplanina",
    storageBucket: "rpk-staraplanina.appspot.com",
    messagingSenderId: "351358100831",
    appId: "1:351358100831:web:f674222cb13b9e7f2d42ea"
  }
};