import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';

import { throwIfAlreadyLoaded } from '@app/core/module-import-guard/module-import-guard';
import { ScriptLoaderDirective } from '@app/core/script-loader/script-loader.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProgressBarService } from './progress-bar/progress-bar.service';
import { CustomErrorHandler } from './error-handling/custom-error-handler';
import { coreServices } from './core-services';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuardService } from './auth/auth-guard.service';

/********************************************************
Singletons, import only in AppModule
********************************************************/

@NgModule({ exports: [
        NgxPermissionsModule,
        ScriptLoaderDirective,
    ],
    declarations: [
        ScriptLoaderDirective
    ], imports: [NgxPermissionsModule.forRoot()], providers: [
        AuthGuardService,
        { provide: 'FileStorageService', useClass: coreServices.fileStorageService },
        ProgressBarService,
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule);
    }
}
