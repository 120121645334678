import { HealthRecord } from '@app/data-access/entities/hr/health-record';

export const MOCK_HEALTHRECORDS: HealthRecord[] = [
    { id: 1, employee_id: 1, examined_date: 1572970698000, active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, employee_id: 2, examined_date: 1571170698000, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, employee_id: 2, examined_date: 4097578698000, active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, employee_id: 1, examined_date: 1572970698000, active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, employee_id: 3, examined_date: 1572970698000, active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, employee_id: 4, examined_date: 1572970698000, active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, employee_id: 5, examined_date: 1572970698000, active: true, createdby_id: 1, timestamp: 0 },
];
