import { IFileStorageService } from './i-file-storage';
import { Inject, Injectable } from '@angular/core';
import { ILoggingService } from '../logging/i-logging.service';
import { Observable } from 'rxjs';

@Injectable()
export class MockFileStorageService implements IFileStorageService {
  private url = '/storage';

  constructor(
    @Inject('LoggingService') private logger: ILoggingService
  ) { }

  exists(file_name: string) {
    return new Observable((observer) => {
      observer.next(false);
      observer.complete();
    });
  }

  serve(file_name: string): string {
    return `${this.url}/serve?file_name=${file_name}`;
  }

  servingUrl(file_name: string): Observable<string> {
    return new Observable((observer) => {
      observer.next(`/mockstorage?file_name=${file_name}`);
      observer.complete();
    });
  }

  upload(file: File) {
    this.logger.debug('uploading file...');
    return new Observable((observer) => {
      observer.next(true);
      observer.complete();
    });
  }
}