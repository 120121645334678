import { TransportCompany } from '@app/data-access/entities/transportation/transport-company';


export const MOCK_TRANSPORTCOMPANIES: TransportCompany[] = [
    { id: 1, name: 'РПК Стара Планина', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'доставчик на малини', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Банан', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Trans Co', active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'Another Trans Co', active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, name: 'Inactive', active: false, createdby_id: 1, timestamp: 0 },
];
