import { DocumentLabel } from '@app/data-access/entities/documentation/document-label';

export const MOCK_DOCUMENTLABELS: DocumentLabel[] = [
    { id: 1, name: 'HACCP', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Бланка', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'ДПП', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Ръководство', active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'ТД', active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, name: 'inactive label', active: false, createdby_id: 1, timestamp: 0 }
];
