import { AirFilter } from '@app/data-access/entities/maintenance/air-filter';

export const MOCK_AIRFILTERS: AirFilter[] = [
    {
        id: 1, brand: 'OMI', model: 'DF', supplier_id: 1, lifespan: 100, active: true, createdby_id: 1, timestamp: 0
    },
    {
        id: 2, brand: 'ABC', model: 'DF 2', supplier_id: 1, lifespan: 100, active: true, createdby_id: 1, timestamp: 0
    }
];
