
import { Address } from '@app/data-access/entities/hr/address';

export const MOCK_ADDRESSES: Address[] = [
    { id: 1, address_line1: 'line 1', city: 'test', country: 'test', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, address_line1: 'line 1', city: 'test', country: 'test', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, address_line1: 'line 1', city: 'test', country: 'test', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, address_line1: 'line 1', city: 'test', country: 'test', active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, address_line1: 'line 1', city: 'test', country: 'test', active: false, createdby_id: 1, timestamp: 0 },
];
