import { TechnologicalProcess } from '@app/data-access/entities/production/technological-process';

export const MOCK_TECHNOLOGICALPROCESSES: TechnologicalProcess[] = [
    { id: 1, name: 'Дълбоко замразяване на малини', stages: [
        {id: 1, name: 'Прием на суровини', active: true, createdby_id: 1, timestamp: 0},
        {id: 2, name: 'Охлаждане', active: true, createdby_id: 1, timestamp: 0},
        {id: 3, name: 'Замразяване', active: true, createdby_id: 1, timestamp: 0},
        {id: 4, name: 'Сортиране', active: true, createdby_id: 1, timestamp: 0},
        {id: 5, name: 'Пакетиране', active: true, createdby_id: 1, timestamp: 0},
        {id: 6, name: 'Съхранение', active: true, createdby_id: 1, timestamp: 0}
    ], active: true, createdby_id: 1, timestamp: 0 },
    { id: 1, name: 'Дълбоко замразяване на ягоди', stages: [
        {id: 7, name: 'Прием на суровини', active: true, createdby_id: 1, timestamp: 0},
        {id: 8, name: 'Измиване', active: true, createdby_id: 1, timestamp: 0},
        {id: 9, name: 'Замразяване', active: true, createdby_id: 1, timestamp: 0},
        {id: 10, name: 'Сортиране', active: true, createdby_id: 1, timestamp: 0},
        {id: 11, name: 'Пакетиране', active: true, createdby_id: 1, timestamp: 0},
        {id: 12, name: 'Съхранение', active: true, createdby_id: 1, timestamp: 0}
    ], active: true, createdby_id: 1, timestamp: 0 }
];
