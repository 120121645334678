import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgressBarService {
  private _store: number[] = []
  stateChanged$ = new BehaviorSubject<boolean>(false);

  setState(ts: number) {
    if (this._store.includes(ts)) {
      this._store.splice(this._store.indexOf(ts), 1)
    } else {
      this._store.push(ts)
    }

    if (this.stateChanged$.value != this._store.length > 0) {
      this.stateChanged$.next(this._store.length > 0)
    }
  }
}
