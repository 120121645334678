import { TemperatureRecord } from '@app/data-access/entities/haccp/temperature-record';

export const MOCK_TEMERATURERECORDS: TemperatureRecord[] = [
    { id: 1, warehouse_id: 1, value: 1, active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, warehouse_id: 2, value: 2, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, warehouse_id: 1, value: 3, active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, warehouse_id: 1, value: 4, active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, warehouse_id: 1, value: 5, active: true, createdby_id: 1, timestamp: 0 },
    { id: 101, warehouse_id: 1, value: 1, active: false, createdby_id: 1, timestamp: 0 }
];
