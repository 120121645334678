import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LogLevel } from './log-level';
import { ILoggingService } from './i-logging.service';

@Injectable()
export class LoggingService implements ILoggingService {
  private level: LogLevel = LogLevel.LOG
  private isErrorEnabled = (): boolean => this.level >= LogLevel.LOG
  private isWarnEnabled = (): boolean => this.level >= LogLevel.LOG
  private isInfoEnabled = (): boolean => this.level >= LogLevel.LOG
  private isDebugEnabled = (): boolean => this.level >= LogLevel.LOG
  private isLogEnabled = (): boolean => this.level >= LogLevel.LOG

  constructor() {
    this.setLevel(environment.logLevel)
  }

  getLogLevel(): LogLevel {
    return this.level
  }

  setLevel(value: LogLevel) {
    this.level = value
  }

  log(...args: any[]): void {
    if (this.isLogEnabled()) {
      console.log(...args)
    }
  }

  error(...args: any[]): void {
    if (this.isErrorEnabled())
      console.error(...args)
  }

  warn(...args: any[]): void {
    if (this.isWarnEnabled())
      console.warn(...args)
  }

  info(...args: any[]): void {
    if (this.isInfoEnabled())
      console.info(...args)
  }

  debug(...args: any[]): void {
    if (this.isDebugEnabled()) {
      if (console['debug']) {
        console.debug(...args)
      } else {
        console.log(...args)
      }
    }
  }
}
