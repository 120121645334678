import { PackagingLog } from '@app/data-access/entities/packaging/packaging-log';

export const MOCK_PACKAGINGLOGS: PackagingLog[] = [
    {
        id: 1, entity_kind: 'Bag', entity_id: 1, count: 1, supplier_id: 3, lot_id: 'test', invoice_url: 'https://google.com',
        transportation_id: 1,
        active: true, createdby_id: 1, timestamp: 0
    },
    {
        id: 1, entity_kind: 'Box', entity_id: 1, count: 1, supplier_id: 4, lot_id: 'test',
        transportation_id: 1,
        active: true, createdby_id: 1, timestamp: 0
    },
    {
        id: 1, entity_kind: 'Tape', entity_id: 1, count: 1, supplier_id: 9, lot_id: 'test',
        transportation_id: 1,
        active: true, createdby_id: 1, timestamp: 0
    },
    {
        id: 1, entity_kind: 'Wrap', entity_id: 1, count: 1, supplier_id: 10, lot_id: 'test',
        transportation_id: 1,
        active: true, createdby_id: 1, timestamp: 0
    },

];
