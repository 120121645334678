import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { RequestCache, RequestCacheWithMap } from '@app/data-access/caching/caching.service';
import { httpInterceptorProviders } from '@app/core/http-interceptors';
import { CoreModule } from '@app/core/core.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { PageNotFoundComponent } from '@app/not-found.component';
import { environment } from '@env/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDb } from './data-access/services/in-memory-db.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ENVIRONMENTS } from '@env/envs';
import { AuthService } from './core/auth/auth.service';
import { WindowRef } from './core/misc/window';
import { LoggingService } from './core/logging/logging.service';
import { SharedModule } from './core/shared.module';

@NgModule({ declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreModule,
        SharedModule,
        environment.name === ENVIRONMENTS.Production || environment.name === ENVIRONMENTS.Stage ? [] : HttpClientInMemoryWebApiModule.forRoot(InMemoryDb, { delay: 1000, passThruUnknownUrl: true }),
        AppRoutingModule], providers: [
        { provide: 'AuthService', useClass: AuthService },
        { provide: RequestCache, useClass: RequestCacheWithMap },
        { provide: 'LoggingService', useClass: LoggingService },
        httpInterceptorProviders,
        WindowRef,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
    constructor(router: Router) { }
}

