import { RawMaterial } from '@app/data-access/entities/materials/rawmaterial';

export const MOCK_RAWMATERIALS: RawMaterial[] = [
    { id: 1, name: 'Херитидж БИО', rawmaterialgroup_id: 1, organic: true, active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Люлин БИО', rawmaterialgroup_id: 1, organic: true, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Херитидж', rawmaterialgroup_id: 1, organic: false, active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Сенга Сенгана', rawmaterialgroup_id: 2, organic: false, active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'Диворастяща', rawmaterialgroup_id: 3, organic: true, active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, name: 'Inactive', rawmaterialgroup_id: 1, organic: true, active: false, createdby_id: 1, timestamp: 0 }
];
