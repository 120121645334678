import { Role } from '@app/data-access/entities/hr/role';

export const MOCK_ROLES: Role[] = [
    { id: 1, name: 'HACCP екип', handle: 'haccp_team', active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Ръководител на HACCP екип', handle: 'haccp_team_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Служител Замразяване', handle: 'freezing_worker', active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Служител Измиване', handle: 'washing_worker', active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'Служител Качествен Контрол', handle: 'quality_control_worker', active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, name: 'Служител Пакетиране', handle: 'packing_worker', active: true, createdby_id: 1, timestamp: 0 },
    { id: 7, name: 'Служител Сортиране', handle: 'sorting_worker', active: true, createdby_id: 1, timestamp: 0 },
    { id: 8, name: 'Управител', handle: 'general_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 9, name: 'Член на HACCP екип', handle: 'haccp_team_member', active: true, createdby_id: 1, timestamp: 0 },
    { id: 10, name: 'Отговорник ДПП 10: Изтегляне на продукти от пазара', handle: 'gmp10_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 11, name: 'Отговорник ДПП 11: Съхранение и реализация на готови продукти', handle: 'gmp11_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 12, name: 'Отговорник ДПП 12: Предпазване на храните от чужди тела и вещества', handle: 'gmp12_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 13, name: 'Отговорник ДПП 14: Химични вещества за почистване и дезинфекция', handle: 'gmp14_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 14, name: 'Отговорник ДПП 13: Програма за отстраняване на отпадъци', handle: 'gmp13_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 15, name: 'Отговорник ДПП 1: Сгради, помещения и оборудване', handle: 'gmp1_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 16, name: 'Отговорник ДПП 2: Прием на суровини, опаковки и пакетиращи материали', handle: 'gmp2_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 17, name: 'Отговорник ДПП 3: Съхранение на суровини, опаковки и пакетиращи материали', handle: 'gmp3_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 18, name: 'Отговорник ДПП 4: Поддържане на хигиенното състояние на обекта', handle: 'gmp4_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 19, name: 'Отговорник ДПП 5: Технологичен процес', handle: 'gmp5_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 20, name: 'Отговорник ДПП 6: Лична хигиена на персонала', handle: 'gmp6_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 21, name: 'Отговорник ДПП 7: Обучение на персонала', handle: 'gmp7_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 22, name: 'Отговорник ДПП 8: Борба с вредители', handle: 'gmp8_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 23, name: 'Отговорник ДПП 9: Програма за самонаблюдение', handle: 'gmp9_manager', active: true, createdby_id: 1, timestamp: 0 },
    { id: 24, name: 'Кризисен екип', handle: 'crisis_team', active: true, createdby_id: 1, timestamp: 0 },
    { id: 25, name: 'Админ', handle: 'admin', active: true, createdby_id: 0, timestamp: 0 },
    { id: 101, name: 'inactive role', handle: 'inactive', active: false, createdby_id: 1, timestamp: 0 },
];