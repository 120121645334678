import { Product } from '@app/data-access/entities/production/product';

export const MOCK_PRODUCTS: Product[] = [
    { id: 1, name: 'Дълбоко Замразена Малина Херитидж БИО',
    quality_id: 1, organic: true, net_weight: 10, active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Дълбоко Замразена Малина Херитидж БИО - Брух',
    quality_id: 2, organic: true, net_weight: 10, active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Дълбоко Замразена Къпина - Диворастяща',
    quality_id: 2, organic: false, net_weight: 10, active: true, createdby_id: 1, timestamp: 0 },
    { id: 100, name: 'inactive product',
    quality_id: 1, organic: false, net_weight: 10, active: false, createdby_id: 1, timestamp: 0 }
];