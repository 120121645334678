import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderByPipe } from '@app/core/pipes/orderby.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MaterialModule } from './material/material.module';

/********************************************************
Import in many places
********************************************************/
@NgModule({
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    OrderByPipe,
  ],
  declarations: [
    OrderByPipe
  ]
})
export class SharedModule { }
