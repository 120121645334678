import { ComplaintLog } from '@app/data-access/entities/crm/complaint-log';

export const MOCK_COMPLAINTLOGS: ComplaintLog[] = [
    {
        id: 1, customer_id: 1, productionlot_id: 1, description: 'test', full_name: 'full name', actions: [
            { description: 'action description', createdby_id: 1, timestamp: 0 }
        ], active: true, createdby_id: 1, timestamp: 0
    },
    { id: 2, customer_id: 2, productionlot_id: 1, description: 'test', full_name: 'full name', actions: [], active: true, createdby_id: 1, timestamp: 0 }
];
