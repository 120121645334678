import { ProductionLot } from '@app/data-access/entities/production/production-lot';

export const MOCK_PRODUCTIONLOTS: ProductionLot[] = [
    { id: 1, product_id: 1, lot_id: 'L111', quantity: 100, ingredients: [
        {rawmaterial_id: 1, rawmateriallot_id: 1, quantity:10, quantity_unit: 'kg' }
    ], packaging: [
        {entity_kind: 'Bag', entity_id: 1, quantity: 4, quantity_unit: '', inventory_kind: 'Bag', inventory_id: 1},
        {entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: '', inventory_kind: 'Box', inventory_id: 1},
        {entity_kind: 'Tape', entity_id: 1, quantity: 1.2, quantity_unit: '', inventory_kind: 'Tape', inventory_id: 1}
    ], waste: [], active: true, createdby_id: 1, timestamp: 1591686906000 },
    { id: 2, product_id: 2, lot_id: 'L222', quantity: 100, ingredients: [
        {rawmaterial_id: 5, rawmateriallot_id: 3, quantity:10, quantity_unit: 'kg' }
    ], packaging: [
        {entity_kind: 'Bag', entity_id: 1, quantity: 4, quantity_unit: '', inventory_kind: 'Bag', inventory_id: 1},
        {entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: '', inventory_kind: 'Box', inventory_id: 1},
        {entity_kind: 'Tape', entity_id: 1, quantity: 1.2, quantity_unit: '', inventory_kind: 'Tape', inventory_id: 1}
    ], waste: [], active: true, createdby_id: 1, timestamp: 1590554906000 },
    { id: 3, product_id: 3, lot_id: 'L333', quantity: 100, ingredients: [
        {rawmaterial_id: 5, rawmateriallot_id: 3, quantity:10, quantity_unit: 'kg' }
    ], packaging: [
        {entity_kind: 'Bag', entity_id: 1, quantity: 4, quantity_unit: '', inventory_kind: 'Bag', inventory_id: 1},
        {entity_kind: 'Box', entity_id: 1, quantity: 1, quantity_unit: '', inventory_kind: 'Box', inventory_id: 1},
        {entity_kind: 'Tape', entity_id: 1, quantity: 1.2, quantity_unit: '', inventory_kind: 'Tape', inventory_id: 1}
    ], waste: [], active: true, createdby_id: 1, timestamp: 1590912306000 },
];
