import { Warehouse } from '@app/data-access/entities/warehouse/warehouse';

export const MOCK_WAREHOUSES: Warehouse[] = [
    { id: 1, name: 'Хладилна камера 1', coldstore: true, storage_types: ['Продукти'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 2, name: 'Хладилна камера 4', coldstore: true, storage_types: ['Суровини'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 3, name: 'Products & Raw materials warehouse', coldstore: true, storage_types: ['Продукти', 'Суровини'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 4, name: 'Зала за охлаждане', coldstore: true, storage_types: ['Суровини'], active: true, createdby_id: 1, timestamp: 0 },
    { id: 5, name: 'Склад за амбалаж', coldstore: false, storage_types: [], active: true, createdby_id: 1, timestamp: 0 },
    { id: 6, name: 'Склад за опаковъчни материали', coldstore: false, storage_types: [], active: true, createdby_id: 1, timestamp: 0 },
    { id: 100, name: 'inactive warehouse', coldstore: true, storage_types: [], active: false, createdby_id: 1, timestamp: 0 }
];
