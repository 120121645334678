import { Supplier } from '@app/data-access/entities/supply_chain/supplier';

export const MOCK_SUPPLIERS: Supplier[] = [
    {
        id: 1, name: 'доставчик на малини', 
        suppliertype_id: 6,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 2, name: 'доставчик на касетки', 
        suppliertype_id: 7,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 3, name: 'доставчик на пликове', 
        suppliertype_id: 5,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 4, name: 'доставчик на кашони', 
        suppliertype_id: 5,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 5, name: 'доставчик на палети', 
        suppliertype_id: 7,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 6, name: 'доставчик на диви плодове', 
        suppliertype_id: 6,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 7, name: 'доставчик на почистващи препарати', 
        suppliertype_id: 1,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 8, name: 'доставчик на измервателни инструменти', 
        suppliertype_id: 3,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 9, name: 'доставчик на тиксо и етикети', 
        suppliertype_id: 5,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 10, name: 'доставчик на стреч фолио', 
        suppliertype_id: 5,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 101, name: 'inactive supplier', 
        suppliertype_id: 1,
        address_ids: [],
        contact_ids: [],
        active: false, 
        createdby_id: 1, 
        timestamp: 0
    },
    {
        id: 11, name: 'доставчик на оборудване', 
        suppliertype_id: 2,
        address_ids: [],
        contact_ids: [],
        active: true, 
        createdby_id: 1, 
        timestamp: 0
    },
];
