import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'orderBy'
} )
export class OrderByPipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
      let reverse = 1;
      if (field.startsWith('-')) {
        reverse = -1;
        field = field.substring(1);
      }

      if (array) {
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
              return -1 * reverse;
            } else if (a[field] > b[field]) {
              return 1 * reverse;
            } else {
              return 0;
            }
          });
      }

      return array;
    }
}
