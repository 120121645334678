import { Directive } from '@angular/core';

@Directive({
  selector: '[appScriptLoader]'
})
export class ScriptLoaderDirective {

  constructor() { }

}
